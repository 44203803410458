<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img :src="require('@/assets/images/logo/imprionBlagajna.svg')" style="height: 50px;">
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        {{$t('login.greeting')}} 👋
                    </b-card-title>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                :label="$t('login.email')"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('login.email')"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="loginObject.email"
                                        :state="errors.length > 0 ? false:null"
                                        name="email"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group :label="$t('login.password')">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('login.password')"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="loginObject.password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="loginObject.remember_me"
                                    name="checkbox-1"
                                >
                                    {{$t('login.remember_me')}}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="loginJWT"
                                :disabled="loading"
                            >
                                {{$t('login.login')}}  <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BSpinner
    } from 'bootstrap-vue'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import {captureException, setTag, setUser} from '@sentry/vue'

    export default {
        components: {
            BSpinner,
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                loading: false,
                loginObject: {
                    email: '',
                    password: '',
                    remember_me: false
                },
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },
        methods: {
            async loginJWT() {
                const valid = await this.$refs.loginValidation.validate()
                if (!valid) return

                const thisIns = this

                try {
                    thisIns.loading = true
                    const loginResponse = await this.$http.post('/api/reseller/v1/login', this.loginObject)

                    setUser({
                        id: loginResponse.data.user_data.id,
                        email: loginResponse.data.user_data.email
                    })
                    setTag('RESELLER_ID', loginResponse.data.user_data.reseller_id)
                    setTag('RESELLER_NAME', loginResponse.data.user_data.reseller_name)

                    await thisIns.$store.dispatch('user/setLoginStatus', true)
                    await thisIns.$store.dispatch('user/updateUserData', loginResponse.data.user_data)
                    localStorage.setItem('userData', JSON.stringify(loginResponse.data.user_data))
                    localStorage.setItem('JWT', loginResponse.data.token_string)

                    const response = await this.$http.get('/api/reseller/v1/client/has_clients')
                    const hasClients = response.data ?? false

                    if (!hasClients) await this.$store.dispatch('user/setUserNoClients')

                    thisIns.$nextTick(function() {
                        thisIns.$router.push('/')
                    })
                } catch (error) {
                    captureException(error)
                    if (error && error.response && error.response.status === 403 && error.response.data === 'user no client') await this.$store.dispatch('user/setUserNoClients')
                    else thisIns.$printWarning(this.$t('login.wrong_password'))
                } finally {
                    thisIns.loading = false
                }
            }

        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
